import { defineStore } from "pinia";
import { Track, TrackProvider } from "@/types/track";
import { User } from "@/types/user";
import {
  PlaylistSubmission,
  PlaylistSubmissionProAction,
} from "@/types/playlist-submission";
import { OpportunitySubmission } from "@/types/opportunity-submission";
import { usePlaylistsStore } from "@/store/playlists";
import { ProAction } from "@/types/playlists";
import { useTrackStore } from "@/store/track";
import { useOpportunitySubmissionStore } from "@/store/opportunity-submission";

interface PlayerStore {
  playingTrack?: Track;
  playingUser?: User;
  isPlaying: boolean;
  splitScreen: boolean;
  mode?: "opportunity" | "playlist";
  playlistSubmission?: PlaylistSubmission;
  opportunitySubmission?: OpportunitySubmission;

  isArchived: boolean;

  hasMessage: boolean;
  isSaved: boolean;
  isLiked: boolean;

  showMessageSubmit: boolean;
  showConnectToSpotify: boolean;
  provider: string;
  previewUrl: string;
  streamUrl?: string;

  barProgress: number;

  spotifyPlayer: any;
  spotifyPlayerStatus: -1 | 0 | 1;
  spotifyTrack: string;

  autoplay: boolean;

  youtubePlayer: any;
  youtubePlayerStatus: -1 | 0 | 1;
  youtubeTrack: string;

  soundcloudTrack: string;
  replaySpotify: boolean;
  replayYoutube: boolean;
  replayMp3: boolean;
  shouldPause: boolean;
  pauseStaticPlayer?: Function;
}

const playlistsStore = usePlaylistsStore();
const opportunitySubmissionStore = useOpportunitySubmissionStore();
const trackStore = useTrackStore();

export const usePlayerStore = defineStore("player", {
  state: (): PlayerStore => ({
    playingTrack: undefined,
    isPlaying: false,
    playingUser: undefined,
    splitScreen: false,
    mode: undefined,
    isArchived: false,
    isLiked: false,
    showMessageSubmit: false,
    showConnectToSpotify: false,
    hasMessage: false,
    isSaved: false,
    provider: "",
    previewUrl: "",
    barProgress: 0,
    spotifyPlayer: undefined,
    spotifyPlayerStatus: 0,
    spotifyTrack: "",
    youtubePlayer: undefined,
    youtubePlayerStatus: 0,
    youtubeTrack: "",
    soundcloudTrack: "",
    opportunitySubmission: undefined,
    replaySpotify: false,
    replayYoutube: false,
    replayMp3: false,
    autoplay: true,
    shouldPause: false,
  }),
  actions: {
    reset() {
      this.playingTrack = undefined;
      this.isPlaying = false;
      this.playingUser = undefined;
      this.splitScreen = false;
      this.mode = undefined;
      this.isArchived = false;
      this.isLiked = false;
      this.showMessageSubmit = false;
      this.hasMessage = false;
      this.isSaved = false;
      this.provider = "";
      this.previewUrl = "";
      this.barProgress = 0;
      this.pauseStaticPlayer = undefined;
    },

    async set(
      track: Track,
      user: User,
      mode: "opportunity" | "playlist",
      data: PlaylistSubmission | OpportunitySubmission,
    ) {
      if (this.playingTrack && this.playingTrack._id === track._id) {
        switch (track.provider) {
          case TrackProvider.spotify:
            this.replaySpotify = true;
            break;
          case TrackProvider.youtube:
            this.replayYoutube = true;
            break;
          case TrackProvider.soundcloud:
          case TrackProvider.upload:
            this.replayMp3 = true;
        }
        return;
      }
      this.playingTrack = track;
      this.playingUser = user;
      this.mode = mode;
      this.isLiked = false;
      this.isSaved = false;
      this.barProgress = 0;

      this.provider = track.provider;

      this.spotifyTrack = "";
      this.youtubeTrack = "";
      this.previewUrl = "";

      switch (track.provider) {
        case TrackProvider.spotify:
          this.spotifyTrack = track.externalId;
          break;
        case TrackProvider.youtube:
          this.youtubeTrack = track.externalId;
          break;
        case TrackProvider.soundcloud:
          const preview = await trackStore.getPreviewByTrackId(track._id);
          this.previewUrl = preview?.streamUrl || preview?.previewUrl || "";
          break;
        case TrackProvider.upload:
          this.previewUrl = track.link;
      }

      if (mode === "opportunity") {
        this.opportunitySubmission = data as OpportunitySubmission;
        this.opportunitySubmission.played = true;

        this.isArchived = this.opportunitySubmission?.archived ?? false;
        this.hasMessage = this.opportunitySubmission?.messaged ?? false;
        this.isSaved = this.opportunitySubmission?.saved ?? false;
        this.isLiked = this.opportunitySubmission?.liked ?? false;

        await opportunitySubmissionStore.play(
          this.opportunitySubmission._id!,
          this.barProgress,
        );
      } else {
        this.playlistSubmission = data as PlaylistSubmission;
        this.addTemporaryPlaylistSubmissionAction(ProAction.PLAY);

        this.isArchived = false;
        this.isArchived =
          this.playlistSubmission?.action?.some(
            (action) => action.action === ProAction.ARCHIVE,
          ) ?? false;

        this.hasMessage =
          this.playlistSubmission?.action?.some(
            (item: PlaylistSubmissionProAction) =>
              item.action === ProAction.MESSAGE,
          ) || false;

        this.isSaved =
          this.playlistSubmission?.action?.some(
            (item: PlaylistSubmissionProAction) =>
              item.action === ProAction.SAVE,
          ) || false;

        this.isLiked =
          this.playlistSubmission?.action?.some(
            (item: PlaylistSubmissionProAction) =>
              item.action === ProAction.LIKE ||
              item.action === ProAction.SUPERLIKE,
          ) || false;

        await playlistsStore.play(this.playlistSubmission._id);
      }
    },

    pause() {
      this.setIsPlaying(false);
    },

    addTemporaryPlaylistSubmissionAction(action: ProAction) {
      playlistsStore.items.forEach((playlistSubmission) => {
        if (playlistSubmission._id === this.playlistSubmission?._id) {
          playlistSubmission.action!.push({
            action: action,
            pro: {
              firstName: "",
              lastName: "",
              _id: "",
            },
            active: true,
          });
        }
      });
    },

    removeTemporaryPlaylistSubmissionAction(action: ProAction) {
      playlistsStore.items.forEach((playlistSubmission) => {
        if (playlistSubmission._id === this.playlistSubmission?._id) {
          playlistSubmission.action = playlistSubmission.action?.filter(
            (item) => item.action !== action,
          );
        }
      });
    },

    setIsPlaying(isPlaying: boolean) {
      this.isPlaying = isPlaying;
    },

    setSplitScreen(splitScreen: boolean) {
      this.splitScreen = splitScreen;
    },

    async playNext() {
      if (this.mode === "opportunity") {
        const opportunitySubmisison = opportunitySubmissionStore.getNext(
          this.opportunitySubmission!._id!,
        );

        if (opportunitySubmisison) {
          await this.set(
            opportunitySubmisison.track!,
            opportunitySubmisison.user!,
            "opportunity",
            opportunitySubmisison,
          );
        } else {
          this.reset();
        }
      } else {
        const submisison = playlistsStore.getNext(this.playlistSubmission!._id);

        if (submisison) {
          await this.set(
            submisison.track!,
            submisison.user!,
            "playlist",
            submisison,
          );
        } else {
          this.reset();
        }
      }
    },

    async archive() {
      if (this.mode === "opportunity") {
        const toArchive = this.opportunitySubmission!._id!;
        await this.playNext();
        await opportunitySubmissionStore.archive(toArchive, this.barProgress);
      } else {
        const toArchive = this.playlistSubmission!._id;
        await this.playNext();
        await playlistsStore.archive(toArchive, this.barProgress);
      }
    },

    async unArchive() {
      if (this.mode === "opportunity") {
        const toArchive = this.opportunitySubmission!._id!;
        await this.playNext();
        await opportunitySubmissionStore.unArchive(toArchive, this.barProgress);
      } else {
        const toArchive = this.playlistSubmission!._id;
        await this.playNext();
        await playlistsStore.unArchive(toArchive, this.barProgress);
      }
    },

    async save() {
      if (this.mode === "opportunity") {
        this.opportunitySubmission!.saved = true;
        this.isSaved = true;
        await opportunitySubmissionStore.save(
          this.opportunitySubmission!._id!,
          this.barProgress,
        );
      } else {
        this.addTemporaryPlaylistSubmissionAction(ProAction.SAVE);
        this.isSaved = true;
        await playlistsStore.save(
          this.playlistSubmission!._id,
          this.barProgress,
        );
      }
    },

    async unSave(remove: boolean) {
      if (this.mode === "opportunity") {
        this.opportunitySubmission!.saved = false;
        const toSave = this.opportunitySubmission!._id!;
        if (remove) {
          await this.playNext();
        }
        await opportunitySubmissionStore.unSave(
          toSave,
          remove,
          this.barProgress,
        );
      } else {
        const toSave = this.playlistSubmission!._id;
        if (remove) {
          await this.playNext();
        }
        this.removeTemporaryPlaylistSubmissionAction(ProAction.SAVE);
        await playlistsStore.unSave(toSave, remove, this.barProgress);
      }
      this.isSaved = false;
    },

    async superlike() {
      if (this.mode === "opportunity") {
        this.opportunitySubmission!.liked = true;
        this.opportunitySubmission!.saved = true;
        this.isLiked = true;
        this.isSaved = true;
        await Promise.all([
          opportunitySubmissionStore.superlike(
            this.opportunitySubmission!._id!,
            this.barProgress,
          ),
          opportunitySubmissionStore.save(
            this.opportunitySubmission!._id!,
            this.barProgress,
          ),
        ]);
      } else {
        this.isSaved = true;
        this.isLiked = true;
        this.addTemporaryPlaylistSubmissionAction(ProAction.SAVE);
        this.addTemporaryPlaylistSubmissionAction(ProAction.LIKE);
        await Promise.all([
          playlistsStore.save(this.playlistSubmission!._id, this.barProgress),
          playlistsStore.superlike(
            this.playlistSubmission!._id,
            this.barProgress,
          ),
        ]);
      }
    },

    async justLike() {
      if (this.mode === "opportunity") {
        this.opportunitySubmission!.liked = true;
        this.isLiked = true;
        await opportunitySubmissionStore.like(
          this.opportunitySubmission!._id!,
          this.barProgress,
        );
      } else {
        this.addTemporaryPlaylistSubmissionAction(ProAction.LIKE);
        this.isLiked = true;
        await playlistsStore.like(
          this.playlistSubmission!._id,
          this.barProgress,
        );
      }
    },

    async sendOpportunitySubmissionMessage(
      message: string,
      opportunitySubmission: OpportunitySubmission,
    ) {
      await opportunitySubmissionStore.submitMessage(
        opportunitySubmission._id as string,
        message,
        this.barProgress,
      );
      opportunitySubmissionStore.opportunitySubmissions.forEach(
        (opportunitySubmission) => {
          if (opportunitySubmission._id === opportunitySubmission._id) {
            opportunitySubmission.messaged = true;
          }
        },
      );
    },
    async sendPlaylistSubmissionMessage(
      message: string,
      playlistSubmission: PlaylistSubmission,
    ) {
      await playlistsStore.submitMessage(
        playlistSubmission._id as string,
        message,
        this.barProgress,
      );
      playlistsStore.items.forEach((playlistSubmission) => {
        if (playlistSubmission._id === playlistSubmission._id) {
          playlistSubmission.action!.push({
            action: ProAction.MESSAGE,
            pro: {
              firstName: "",
              lastName: "",
              _id: "",
            },
            active: true,
          });
        }
      });
    },
  },
});
